<template>
  <div>
    <navigation-view />
    <sticky-view />

    <section class="m-auto justify-center items-center">
      <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
        <img src="../assets/Online-BCA-in-Cloud-Computing-Desktop-logo.webp" class="hidden md:block w-full" />
        <img src="../assets/online-bca-in-cloud-computing-mobil-logo.webp" class="md:hidden w-full object-cover" />
        <div class="max-w-7xl mx-auto flex place-items-center">
          <div class="absolute text-white text-center lg:text-left mx-auto lg:top-[18%] top-3">
            <div class="lg:ml-[12%]">
              <h1 class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]">
                BCA in <br />Cloud Computing & Cyber
                Security
              </h1>

              <h2 class="text-white lg:text-lg text-[12px] lg:w-[70%] pb-4 lg:text-left text-center md:ml-0 m-auto">
                Become an architect of the Digital Future with our Online BCA in
                Cloud Computing & Cyber Security
              </h2>
            </div>

            <div class="flex mt-6 md:hidden">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div class="hidden md:block">
              <div
                class="flex flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52 ">
                <div class="w-[20%] flex justify-center py-2 border-white border-r">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/banner-3-Years.png" width="25" alt />
                    2 Years
                  </span>
                </div>
                <div class="w-[40%] flex justify-center py-2 border-white">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/banner-Total-Credits--140.png" width="30" alt />
                    Live Online Classes
                  </span>
                </div>
                <!-- <div class="w-[45%] flex justify-center py-2">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/credit.webp" width="30" alt />
                    EMI starting ₹3,210/month*
                  </span>
                </div> -->
              </div>
            </div>
            <div
              class="flex w-full flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52 md:hidden">
              <div class="w-[40%] flex justify-center py-2 border-white border-r">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/banner-3-Years.png" width="25" alt />
                  2 Years
                </span>
              </div>
              <div class="w-[60%] flex justify-center py-2">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/banner-Total-Credits--140.png" width="30" alt />
                  Live Online Classes
                </span>
              </div>

            </div>
            <!-- <div
              class="flex w-full flex-row text-sm bg-gradient-to-r  border-white border-t from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52 md:hidden">

              <div class="w-[100%] flex justify-center py-2">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/credit.webp" width="30" alt />
                  EMI starting ₹3,210/month*
                </span>
              </div>
            </div> -->
            <div class="flex mb-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div class="flex gap-4 place-content-center lg:place-content-start mb-4 md:ml-[12%]">
              <a href="https://apply.onlineyenepoya.com/csma" target="_blank"
                class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl">Apply Now</a>
              <div @click="is_widget = true">
                <button class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                  href="https://api.onlineyenepoya.com/media/BCA_Cloud_Computing_and_Cyber_Security_59461.pdf"
                  target="_blank">
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-7xl mx-auto p-4">
      <h2 class="text-center lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:p-4 p-1">
        About Specialization - Cloud Computing & Cyber Security
      </h2>

      <!-- <h6
        class="text-center lg:text-xl text-[20px] sm:text-[20px] text-gray-700"
      >International Finance and Accounting</h6>-->
      <div class="text-left text-justify readmore-section p-4">
        <p>
          In today's dynamic tech landscape, there is a substantial surge in the
          demand for certified professionals specializing in Cloud Computing and
          Security. Mastery as a security administrator requires proficiency in
          managing network infrastructure, addressing server and cloud
          infrastructure challenges, and expertise in crafting end-to-end
          solutions for network and server infrastructure.
        </p>
        <p class="paragraph hidden">
          Our BCA in cloud computing and cyber security is designed to elevate
          you to an expert level, covering essential aspects such as Cisco
          networking, Microsoft Windows server technologies, Azure & AWS cloud
          platforms, and Check Point Firewall & security domains. Upon
          successful completion of the BCA in Cloud Computing and Cyber
          Security, you'll be awarded international certifications, solidifying
          your status as a highly skilled professional in hardware and
          networking.
        </p>
        <!-- <p>
          <strong
            >UGC Entitled | Online Degree | NAAC ‘A+’ Grade Accredited |
            Placement Support</strong
          >
        </p>
        <p>
     <strong>
            For further information, please contact us at <a href="tel:07666830000">07666830000 </a> or email us at <a href="mailto:meghana@onlineyenepoya.com">meghana@onlineyenepoya.com</a>
          </strong>
        </p> -->

        <p class="flex align-middle place-content-end gap-2">
          <button class="readmore-btn text-gray-600">Read More</button>
          <img src="../assets/program/read-more.png" width="25" alt />
        </p>
      </div>
    </section>

    <section class="xl:flex lg:gap-12 max-w-7xl mx-auto p-4 text-center lg:flex-row flex flex-col-reverse xl:text-left">
      <div class="lg:w-1/2">
        <h1 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4">
          Program Highlights
        </h1>
        <div>
          <ul class="divide-y text-left">
            <li class="flex gap-4 p-2">
              <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Mastery of cloud computing concepts, including IaaS, PaaS, and
                SaaS models
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Practical exposure to leading cloud platforms like AWS, Azure,
                and Google Cloud
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Hands-on experience in ethical hacking, penetration testing, and
                vulnerability assessment
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Training in cutting-edge technologies such as threat
                intelligence and security analytics
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-r m-4 hidden lg:block"></div>
      <div class="lg:w-1/2">
        <h2 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4">
          Eligibility
        </h2>
        <ul class="divide-y text-left">
          <li class="flex gap-4 p-2">
            <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
            <p>
              Successfully clear 10th and 12th examinations accredited by either
              State Board, CBSE, NIOS, IGCSE, IB, ICSE, recognized by respective
              State or Central Government
            </p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <section class="max-w-7xl mx-auto lg:py-6 py-2">
      <h1
        class="text-3xl text-gray-700 text-center font-bold p-2 lg:text-3xl text-[20px] sm:text-[20px]"
      >
        Fee Structure
      </h1>
      <div
        class="mx-auto bg-white rounded-lg shadow-lg text-center p-2 lg:w-full w-[90%] sm:w-[90%]"
      >
        <div class="m-4">
          <div class="tabcontent flex flex-col lg:flex-row">
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                Domestic fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">₹ 25,000</td>
                  <td class="border-4 border-white">₹ 75,000</td>
                </tr>
              </table>
            </div>
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                International fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">$ 300</td>
                  <td class="border-4 border-white">$ 900</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex">
      <div class="px-4 lg:py-4 py-1 gap-6 justify-between">
        <h2 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4">
          Career Opportunities
        </h2>

        <div class="text-left text-justify p-4">
          <p>
            The integration of cloud computing and cybersecurity skills opens up
            diverse career paths in the dynamic IT landscape. Graduates with an
            Online BCA in Cloud Computing and Cybersecurity can explore exciting
            roles such as:
          </p>
        </div>

        <div class="px-4 lg:py-8 py-1 gap-6 flex flex-col lg:flex-row mx-0 justify-center">
          <div class="flex lg:w-[50%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Cloud Solutions Architect</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Security Consultant</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Cybersecurity Analyst</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Cloud Engineer</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Ethical Hacker</p>
              </li>
            </ul>
          </div>

          <div class="flex lg:w-[80%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>DevOps Engineer</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Incident Responder</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Cloud Security Specialist</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Digital Forensic Analyst</p>
              </li>
              <li class="flex gap-4 p-2">
                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                <p>Network Security Specialist</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>


    <section class="max-w-7xl mx-auto lg:py-6 py-2">
      <h1 class="text-3xl text-gray-700 text-center font-bold p-2 lg:text-3xl text-[20px] sm:text-[20px]">
        Fee Structure
      </h1>
      <div class="mx-auto bg-white rounded-lg shadow-lg text-center p-2 lg:w-full w-[90%] sm:w-[90%]">
        <div class="m-4">
          <!-- Tab content -->
          <div class="tabcontent flex flex-col lg:flex-row">
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]">
                Domestic fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">₹ 25,000</td>
                  <td class="border-4 border-white">₹ 75,000</td>
                </tr>
              </table>
            </div>
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]">
                International fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <!-- <th class="border-4 border-white">Semester x 6</th> -->
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">$ 400</td>
                  <td class="border-4 border-white">$ 1,200</td>
                  <!-- <td class="border-4 border-white">$ 2,400</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="is_widget">
      <div class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
        <div class="flex items-center justify-center min-h-screen">
          <div class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4">
            <div class="npf_wgts" data-height="344px" :data-w="this.widget_id2"></div>
            <button @click="is_widget = false" class="absolute top-0 right-1 bg-white rounded-full p-1 z-50">
              <svg class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path fill-rule="evenodd"
                  d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
                  clip-rule="evenodd" />
              </svg>
            </button>

            <div class>
              <div class="flex items-center justify-center">
                <div class="relative bg-white rounded-lg shadow-sm">
                  <h4 class="font-bold text-lg px-2 py-2">
                    Download Brochure
                    <br />
                  </h4>
                  <div>
                    <form class="space-y-3 p-4 font-normal" @submit.prevent="brochureSubmit">
                      <input type="text" placeholder="Enter Name *" id="FirstName"
                        class="p-1 rounded-sm w-full border-gray-300" v-model="formInfo.firstName" />
                      <input type="email" placeholder="Enter Email Address *" id="EmailAddress"
                        class="p-1 rounded-sm w-full border-gray-300" v-model="formInfo.email" />

                      <input type="tel" placeholder="Enter Mobile Number *" id="phone" maxlength="10"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        class="p-1 rounded-sm w-full border-gray-300" required />

                      <select name="mx_Program" id value="mx_Program" aria-placeholder="Select Program"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden" required>
                        <option value="BCA">BCA</option>
                      </select>
                      <select name="mx_Elective" id="mx_Elective" aria-placeholder="Select Elective"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden">
                        <option value="none">Select Specialization *</option>
                        <option value=" Computer Science and IT">
                          Cloud Computing & Cyber Security
                        </option>
                      </select>

                      <button type="submit"
                        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center">
                        Download Brochure &#10148;
                      </button>

                      <!-- <button
                        type="submit"
                        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
                      >
                        Download Brochure &#10148;
                      </button>-->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <faq-view />
    <section class="lg:pt-40 pt-8 sm:pt-8 pb-10">
      <div
        class="lg:w-full w-[90%] sm:w-[90%] mx-auto max-w-7xl  border-2 border-gray-400 rounded-lg lg:p-16 p-4 relative m-4 sm:p-4">
        <div class="flex flex-col gap-5">
          <div>
            <h3 class="font-bold md:text-[28px] text-lg text-left">
              Have any questions?
            </h3>
            <h3 class="font-bold text-[18px] text-left">
              <br />
              We are just a click away!
            </h3>
          </div>
          <div class="flex flex-col lg:flex-row gap-5">

            <!-- <a href="https://apply.onlineyenepoya.com/" target="_blank"
                >B.COM</a 
              > -->
            <div class="flex">
              <h5 class="font-bold lg:text-lg text-[16x] px-2 py-2 rounded-xl border-2">
                <a href="tel:07666830000 " class="flex">
                  <img alt="call" src="../assets/telephone.png" width="25" height="25" />
                  <span class="flex pl-3 pt-2">076668 30000 </span>
                </a>
              </h5>
            </div>


            <div class="flex px-3">
              <h5 class="font-bold lg:text-lg text-[16px] px-2 py-2 rounded-xl border-2">
                <a href="mailto:meghana@onlineyenepoya.com" target="_blank" class="flex"><img alt="whatsapp"
                    src="../assets/mail.png" width="30" height="30" class="flex" />
                  <span class="flex pl-3 pt-2">meghana@onlineyenepoya.com </span>
                </a>

              </h5>
            </div>
            <!-- <h5
              class="text-white font-bold lg:text-lg text-[16px] px-2 py-2 bg-[#9C9A9B] rounded-xl border-2"
            >
              <a href="https://apply.onlineyenepoya.com/" target="_blank"
                >BCA</a
              >
            </h5> -->
          </div>
        </div>
        <div class="absolute right-0 bottom-0">
          <!-- &lt;img src=&quot;../assets/aboutus/join_our_degree.webp&quot; class=&quot;lg:hidden sm:block w-[50%] float-right&quot; alt /&gt; --><img
            src="/img/join_our_degree.7c2c51e9.webp" class="lg:block hidden" alt="" />
        </div>
      </div>
    </section>
    <footer-view />
  </div>
</template>

<script>
import $ from "jquery";
import FaqView from "../components/FaqView.vue";
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
import StickyView from "../components/StickyView.vue";

import { useHead } from "@vueuse/head";

import axios from "axios";
export default {
  components: { FaqView, StickyView, FooterView, NavigationView },

  setup() {
    useHead({
      // Can be static or computed
      title: "Online BCA in Cloud Computing & Cyber Security | Yenepoya Online",
      meta: [
        {
          name: `description`,
          content:
            "Catalyze your IT journey with our accredited online BCA program in Cloud Computing & Cyber Security Drive innovation and lay the foundation of an exciting career.",
        },
      ],

      link: [
        {
          rel: "canonical",
          href: "https://onlineyenepoya.com/programs/online-bca-cloud-computing-and-cyber-security",
        },
      ],
    });
  },

  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      formInfo: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },

  methods: {
    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value,
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value,
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value,
        },
        {
          Attribute: "mx_Program",
          Value: "BCA",
        },

        {
          Attribute: "mx_Elective",
          Value: " Cloud Computing & Cyber Security ",
        },
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,
          { cross: true }
        )
        .then(async () => {
          await fetch(
            `https://api.onlineyenepoya.com/media/BCA_Cloud_Computing_and_Cyber_Security_59461.pdf`
          )
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = `BCA-Cloud-Computing-and-Cyber-Security-brochure.pdf`;
              a.click();
            });
          setTimeout(() => {
            window.location.href = "/brochure/bba-thankyou";
          }, 2000);
        })
        .catch((error) => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
  },

  mounted() {
    $(".readmore-btn").on("click", function (e) {
      var target = $(e.target).parents(".readmore-section");
      var paragraph = target.find(".paragraph");

      if (paragraph.hasClass("hidden")) {
        paragraph.removeClass("hidden");
        $(this).text("Read Less");
      } else {
        paragraph.addClass("hidden");
        $(this).text("Read More");
      }
    });

    $(document).ready(function () {
      $(".moreBox1").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore1").show();
      }
      $("#loadMore1").on("click", function (e) {
        e.preventDefault();
        $(".moreBox1:hidden").slice(0, 4).slideDown();
        if ($(".moreBox1:hidden").length == 0) {
          $("#loadMore1").fadeOut("slow");
        }
      });
    });
  },
};
</script>

<style></style>
