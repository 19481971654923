<template>
  <div>
    <section class="lg:p-4 -mt-8 sm:-mt-8 lg:py-8 max-w-7xl mx-auto">
      <h1 class="text-3xl text-gray-700 font-bold text-center p-2  lg:text-3xl text-[20px] sm:text-[20px]">Course Matrix</h1>
      <div class="lg:flex lg:gap-4">
        <div>
          <div data-accordion="collapse" class="">
            <h2 id="accordion-collapse-heading-7" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b6"
                data-accordion-target="#accordion-collapse-body-7"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-7"
              >
                <span class="text-black">Semester 1  (24 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-7"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-7"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
  <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>English</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Business Management</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Financial Accounting - I</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Modern Business Environment</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Business and Commercial Law</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Business Mathematics</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-8" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b7"
                data-accordion-target="#accordion-collapse-body-8"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-8"
              >
                <span class="text-black">Semester 2 (24 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-8"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-8"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
 <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Business Economics</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Cost and Management Accounting</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Financial Accounting - II</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Corporate Finance</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Quantitative Techniques</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Communicative English</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-9" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b8"
                data-accordion-target="#accordion-collapse-body-9"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-9"
              >
                <span class="text-black">Semester 3 (20 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-9"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-9"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
 <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Marketing Management</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Direct Tax</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Auditing</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Macroeconomics</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Environmental Science</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-accordion="collapse">
            <h2 id="accordion-collapse-heading-10" class="pt-4 moreBox1 lg:block hidden">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b9"
                data-accordion-target="#accordion-collapse-body-10"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-10"
              >
                <span class="text-black">Semester 4 (20 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-10"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-10"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
 <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Corporate Accounting</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Organization Behavior and HRM</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Strategic Financial Management</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Advanced Cost and Management Accounting</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Introduction To Business Analytics</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-11" class="pt-4 moreBox1 lg:block hidden">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b10"
                data-accordion-target="#accordion-collapse-body-11"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-11"
              >
                <span class="text-black">Semester 5 (20 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-11"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-11"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
 <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Financial Markets and Instruments</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Goods and Service Tax and Customs Law</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>International Business and Finance</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Entrepreneurship</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Financial Modeling with Spreadsheets</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-18" class="pt-4 moreBox1 lg:block hidden">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b11"
                data-accordion-target="#accordion-collapse-body-18"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-18"
              >
                <span class="text-black">Semester 6 (24 Credits)</span>
                <img src="../../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-18"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-18"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
 <ul class=" divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Banking and Financial Services</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>ICT for Business</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Forensic Accounting and Fraud Detection</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Research Methodology</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Ethics and Values</p>
                  </li>
                  
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt=""
                      class="h-5"
                    />
                    <p>Computerized Accounting System</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
         <div class="flex justify-center mt-4">
            <div class="flex align-middle place-content-end gap-2 lg:hidden" id="loadMore1">
             
              <button class="readmore-btn text-gray-600"><a href="#">View More</a></button><img src="../../assets/about/read-more.png" width="25" alt="">
            </div>
          </div>
      </div>
    </section>
  </div>
</template>


<script>
import $ from "jquery";
export default {
   
    mounted() {
        $(document).ready(function () {
      $(".moreBox1").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore1").show();
      }
      $("#loadMore1").on("click", function (e) {
        e.preventDefault();
        $(".moreBox1:hidden").slice(0, 4).slideDown();
        if ($(".moreBox1:hidden").length == 0) {
          $("#loadMore1").fadeOut("slow");
        }
      });
    });
    },
}

   
</script>

<style></style>
