<template>
  <div>
    <section class="w-full mx-auto my-10 max-w-7xl mb-8">
      <h1 class="font-extrabold text-3xl text-gray-700 text-center p-4">
        Specializations Offered
      </h1>

      <!-- Main Tabs -->
      <ul class="flex justify-center gap-8 mb-0 list-none px-2 md:px-0">
        <li v-for="mainTab in mainTabs" :key="mainTab.id" class="text-center">
          <button
            @click="setActiveMainTab(mainTab.id)"
            :class="{
              'text-white bg-[#94C34D] rounded':
                activeMainTab === mainTab.id,
              'text-white bg-gray-500 rounded': activeMainTab !== mainTab.id,
            }"
            class="text-[15px] w-full font-bold uppercase px-5 py-3 block"
          >
            {{ mainTab.title }}
          </button>
        </li>
      </ul>

      <!-- Sub Tabs -->
      <ul
        v-if="activeMainTab"
        class="flex justify-center gap-3 mt-3 list-none px-2 md:px-0 md:py-4"
      >
        <li
          v-for="program in getPrograms()"
          :key="program.id"
          class="w-[20%] text-center"
        >
          <button
            @click="setActiveProgram(program.id)"
            :class="{
              'text-[#94C34D] border-b-2 border-[#94C34D] rounded': activeProgram === program.id,
              'text-[#6b7280] border-b-2 border-[#6b7280] rounded': activeProgram !== program.id,
            }"
            class="text-[15px] w-full font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block"
          >
            {{ program.title }}
          </button>
        </li>
      </ul>
      <!-- Carousel OR Centered Cards -->
      <div v-if="activeProgram && getSpecializations().length > 0" class="my-4">
        <!-- Use Carousel when shouldSlide is true -->
        <Carousel
          v-if="shouldSlide"
          :wrap-around="true"
          :pause-autoplay-on-hover="true"
          :autoplay="false"
          :autoplay-timeout="5000"
          :transition="2000"
          :mouse-drag="true"
          :touch-drag="true"
          :breakpoints="breakpoints"
          class="w-full"
        >
          <Slide
            v-for="spec in visibleSpecializations"
            :key="spec.id"
            class="flex justify-center"
          >
            <div
              class="p-6 bg-white border border-gray-200 rounded-lg shadow-xl mx-4 h-[270px] md:max-w-[385px] w-full"
            >
              <img :src="spec.thumb" alt="Specialization Image" />
              <h5 class="text-left text-base font-bold text-gray-900 pt-2">
                {{ spec.title }}
              </h5>
              <div class="flex justify-between items-center pt-4">
                <div class="flex flex-col gap-3 w-[65%]">
                  <div class="flex gap-4">
                    <img
                      src="../assets/program/Live-Online-Classes.png"
                      alt
                      class="w-[20px] h-[22px]"
                    />
                    <p class="text-sm text-gray-700">Live Online Classes</p>
                  </div>
                  <div class="flex gap-1 md:gap-4">
                    <img
                      src="../assets/program/2-Years-4-Semester.png"
                      alt
                      class="w-[20px] h-[22px]"
                    />
                    <p class="text-sm text-gray-700">{{ spec.duration }}</p>
                  </div>
                </div>
                <div>
                  <a
                    :href="spec.elective_url"
                    class="text-white bg-[#81C8DC] px-3 py-2 md:px-4 md:py-2 rounded-xl text-xs md:text-sm font-semibold"
                  >
                    Know more
                  </a>
                </div>
              </div>
            </div>
          </Slide>

          <!-- ✅ Dots Navigation -->
          <template #addons>
  <Navigation>
    <button class="carousel__prev">&#8592;</button> <!-- ← Left Arrow -->
    <button class="carousel__next">&#8594;</button> <!-- → Right Arrow -->
  </Navigation>
  <Pagination />
</template>

        </Carousel>

        <!-- If shouldSlide is false (3 or fewer), use flex layout -->
        <div v-else class="flex justify-center flex-wrap gap-6">
          <div
            v-for="spec in visibleSpecializations"
            :key="spec.id"
            class="p-6 bg-white border border-gray-200 rounded-lg shadow-xl h-[270px] max-w-[385px] w-full"
          >
            <img :src="spec.thumb" alt="Specialization Image" />
            <h5 class="text-left text-base font-bold text-gray-900 pt-2">
              {{ spec.title }}
            </h5>
            <div class="flex justify-between items-center pt-4">
              <div class="flex flex-col gap-3 w-[65%]">
                <div class="flex gap-4">
                  <img
                    src="../assets/program/Live-Online-Classes.png"
                    alt
                    class="w-[20px] h-[22px]"
                  />
                  <p class="text-sm text-gray-700">Live Online Classes</p>
                </div>
                <div class="flex gap-1 md:gap-4">
                  <img
                    src="../assets/program/2-Years-4-Semester.png"
                    alt
                    class="w-[20px] h-[22px]"
                  />
                  <p class="text-sm text-gray-700">{{ spec.duration }}</p>
                </div>
              </div>
              <div>
                <a
                  :href="spec.elective_url"
                  class="text-white bg-[#81C8DC] px-3 py-2 md:px-4 md:py-2 rounded-xl text-xs md:text-sm font-semibold"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      activeMainTab: "masters",
      activeProgram: "mba",
      mainTabs: [
        { id: "masters", title: "Masters" },
        { id: "bachelors", title: "Bachelors" },
      ],
      programs: [
        { id: "mba", title: "MBA", parent: "masters" },
        { id: "mca", title: "MCA", parent: "masters" },
        { id: "bba", title: "BBA", parent: "bachelors" },
        { id: "bca", title: "BCA", parent: "bachelors" },
        { id: "bcom", title: "BCOM", parent: "bachelors" },
      ],
      specializations: [
        // MBA Specializations
        {
          id: "mba_marketing",
          title: "MBA in Marketing",
          parent: "mba",
          thumb: require("../assets/program/Marketing.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-marketing",
        },
        {
          id: "mba_finance",
          title: "MBA in Finance",
          parent: "mba",
          thumb: require("../assets/program/Finance.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-finance",
        },
        {
          id: "mba_human-resource",
          title: "MBA in Human Resource Management",
          parent: "mba",
          thumb: require("../assets/program/Human_resource.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-human-resource",
        },
        {
          id: "mba_International_Finance",
          title: "MBA in International Finance (ACCA)",
          parent: "mba",
          thumb: require("../assets/program/International_Finance.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-international-finance",
        },
        {
          id: "mba_Data_Science_Analytics",
          title: "MBA in Data Science and Analytics",
          parent: "mba",
          thumb: require("../assets/program/Data_Science_Analytics.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-data-science-and-analytics",
        },
        {
          id: "mba_marketing_Finance",
          title: "MBA in Marketing and Finance",
          parent: "mba",
          thumb: require("../assets/program/marketing_Finance.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-marketing-and-finance",
        },
        {
          id: "mba_Marketing_Human_resource",
          title: "MBA in Marketing and Human Resource Management",
          parent: "mba",
          thumb: require("../assets/program/Marketing_Human_resource.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-marketing-and-human-resource",
        },
        {
          id: "mba_Finance_Human_resource",
          title: "MBA in Human Resource Management and Finance",
          parent: "mba",
          thumb: require("../assets/program/Finance_Human_resource.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-finance-and-human-resource",
        },
        {
          id: "mba_healthcare_Management",
          title: "MBA in Healthcare Management",
          parent: "mba",
          thumb: require("../assets/program/healthcare_Management.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mba-healthcare-management",
        },

        // MCA Specializations
        {
          id: "mca-computer-science-and-it",
          title: "MCA in Computer Science and Information Technology",
          parent: "mca",
          thumb: require("../assets/program/mca_Computer_Science_IT.webp"),
          duration: "2 Years | 8 Semesters",
          elective_url: "/programs/online-mca-computer-science-and-it",
        },

        // BCOM Specializations
        {
          id: "bcom-accounting-and-finance",
          title: "B.Com in Accounting and Finance",
          parent: "bcom",
          thumb: require("../assets/program/bcom-accounting-and-finance.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url: "/programs/online-bcom-accounting-and-finance",
        },
        {
          id: "bcom-international-finance-and-accounting",
          title: "B.Com in International Finance and Accounting",
          parent: "bcom",
          thumb: require("../assets/program/bcom-international-finance-and-accounting.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url:
            "/programs/online-bcom-international-finance-and-accounting",
        },

        // BBA Specializations
        {
          id: "bba-general-management",
          title: "BBA in General Management",
          parent: "bba",
          thumb: require("../assets/program/bba-general-management.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url: "/programs/online-bba-general-management",
        },
        {
          id: "bba-logistics-and-supply-chain-management",
          title: "BBA in Logistics and Supply Chain Management",
          parent: "bba",
          thumb: require("../assets/program/bba-logistics-and-supply-chain-management.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url:
            "/programs/online-bba-logistics-and-supply-chain-management",
        },
        {
          id: "bba-healthcare-management",
          title: "BBA in Healthcare Management",
          parent: "bba",
          thumb: require("../assets/program/bba-healthcare-management.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url: "/programs/online-bba-healthcare-management",
        },

        // BCA Specializations
        {
          id: "bca-computer-science-and-it",
          title: "BCA in Computer Science and IT",
          parent: "bca",
          thumb: require("../assets/program/bca_Computer_Science_IT.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url: "/programs/online-bca-computer-science-and-it",
        },
        {
          id: "bca-cloud-computing-and-cyber-security",
          title: "BCA in Cloud Computing & Cyber Security",
          parent: "bca",
          thumb: require("../assets/program/bca_Cloud_computing_Cyber_Security.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url:
            "/programs/online-bca-cloud-computing-and-cyber-security",
        },
        {
          id: "bca-animation-and-graphic-design",
          title: "BCA in Multimedia and Animation",
          parent: "bca",
          thumb: require("../assets/program/bca_Multimedia_Animation.webp"),
          duration: "3 Years | 6 Semesters",
          elective_url: "/programs/online-bca-animation-and-graphic-design",
        },
      ],
    };
  },
  methods: {
    setActiveMainTab(tabId) {
      this.activeMainTab = tabId;
      const firstProgram = this.programs.find((p) => p.parent === tabId);
      if (firstProgram) {
        this.activeProgram = firstProgram.id;
      }
    },
    setActiveProgram(programId) {
      this.activeProgram = programId;
    },
    getPrograms() {
      return this.programs.filter((p) => p.parent === this.activeMainTab);
    },
    getSpecializations() {
      return this.specializations.filter(
        (s) => s.parent === this.activeProgram
      );
    },
  },
  computed: {
    breakpoints() {
      return {
        1024: { itemsToShow: 3 },
        768: { itemsToShow: 1 },
        480: { itemsToShow: 1 },
      };
    },
    visibleSpecializations() {
      return this.getSpecializations();
    },
    shouldSlide() {
      return this.visibleSpecializations.length > 3;
    },
  },
};
</script>
<style>
/* Carousel Navigation (Previous/Next buttons) */
.carousel-navigation {
  display: flex;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.carousel__next, .carousel__prev {
  background: #81C8DC !important;
  color: white;
  font-size: 16px; /* Bigger size */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-navigation .prev:hover,
.carousel-navigation .next:hover {
  background: #81C8DC !important;
}

.carousel-navigation .prev {
  left: 10px;
}

.carousel-navigation .next {
  right: 10px;
}

/* Pagination Dots */
.carousel__pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-pagination .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-pagination .dot.active {
  background-color: #81C8DC;
}

.carousel-pagination .dot:hover {
  background-color: #5e9bb7;
}
</style>
