<template>
    <div>
        <navigation-view />
        <sticky-view />

        <section class="m-auto justify-center items-center">
            <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
                <img src="../assets/mba/Online-MBA-in-Human-Resource-Management.webp" class="hidden md:block w-full" />
                <img src="../assets/mba/mobile/Online-MBA-in-Human-Resource-Management.webp" class="md:hidden w-full object-cover" />
                <div class="max-w-7xl mx-auto flex place-items-center">
                    <div class="absolute text-white text-center lg:text-left mx-auto lg:top-[18%] -top-6">
                        <div class="lg:ml-[13%]">
                            <h1 class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]">
                                MBA in <br />Human Resource Management
                            </h1>

                            <h2 class="text-white text-lg w-[80%] lg:text-left text-center md:ml-0 m-auto">
                                Transform Organizations, Elevate Careers with our Online MBA in
                                Human Resource Management
                            </h2>
                        </div>
                        <div class="flex mt-6">
                            <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
                            <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
                        </div>
                        <div class="hidden md:block">
              <div
                class="flex flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 ">
                <div class="w-[16%] flex justify-center py-2 border-white border-r">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/banner-3-Years.png" width="25" alt />
                    3 Years
                  </span>
                </div>
                <div class="w-[30%] flex justify-center py-2 border-white border-r mr-1">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/banner-Total-Credits--140.png" width="30" alt />
                    Live Online Classes
                  </span>
                </div>
                <div class="w-[30%] flex justify-center py-2">
                  <span class="flex items-center text-base font-bold md:gap-2">
                    <img src="@/assets/Programpage/banner/credit.webp" width="30" alt />
                    EMI starting ₹3,210/month*
                  </span>
                </div>
              </div>
            </div>
            <div
              class="flex w-full flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52 md:hidden">
              <div class="w-[40%] flex justify-center py-2 border-white border-r">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/banner-3-Years.png" width="25" alt />
                  2 Years
                </span>
              </div>
              <div class="w-[60%] flex justify-center py-2">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/banner-Total-Credits--140.png" width="30" class="mr-1" alt />
                  Live Online Classes
                </span>
              </div>

            </div>
            <div
              class="flex w-full flex-row text-sm bg-gradient-to-r  border-white border-t from-[#7ab422] from-0% via-[#72a821] via-100% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52 md:hidden">

              <div class="w-[100%] flex justify-center py-2">
                <span class="flex items-center text-base font-bold md:gap-2">
                  <img src="@/assets/Programpage/banner/credit.webp" width="30" alt />
                  EMI starting ₹3,210/month*
                </span>
              </div>
            </div>
                        <div class="flex mb-6">
                            <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
                            <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
                        </div>
                        <div class="lg:ml-[13%]">
                            <h2 class="text-white text-xl lg:text-left text-center md:ml-0 m-auto">
                                Batch start: July 2025
                            </h2>
                        </div>
                        <!-- <div class="flex gap-4 place-content-center lg:place-content-start mb-4 lg:ml-32">
                            <a href="https://apply.onlineyenepoya.com/" target="_blank"
                                class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl">Apply Now</a>

                            <div @click="is_widget = true">
                                <button class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                                    href="https://api.onlineyenepoya.com/media/MBA_General_Management_30081.pdf"
                                    target="_blank">
                                    Download Brochure
                                </button>
                            </div>
                        </div> -->

                    </div>
                </div>
                <!-- <div v-if="is_widget" class>
            <downpopView
              @closeWidget="is_widget = false"
              :widget_prop_id="this.brochure_widget"
            />
          </div> -->
            </div>
        </section>


        <section class="max-w-7xl mx-auto p-4">
            <h2 class="text-center lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:p-4 p-1">
                About Specialization - Human Resource Management
            </h2>

            <!-- <h6
          class="text-center lg:text-xl text-[20px] sm:text-[20px] text-gray-700"
        >International Finance and Accounting</h6>-->
            <div class="text-left text-justify readmore-section p-4">
                <p>
                    The MBA in Human Resource Management is designed to develop future HR leaders with the knowledge and
                    skills to manage human capital effectively. This program blends theoretical concepts with real-world
                    applications, ensuring students understand workforce planning, talent acquisition, employee
                    engagement, and organizational behavior. It emphasizes leadership development, strategic HR
                    planning, and ethical decision-making, preparing graduates to drive workforce productivity and
                    business success.
                </p>

                <p class="paragraph hidden">
                    Students will gain expertise in HR analytics, labor laws, performance management, and change
                    management. With a flexible online learning model, this program is ideal for working professionals
                    looking to advance their careers in HR roles across diverse industries, including corporate,
                    consulting, and entrepreneurial ventures.
                </p>

                <p class="flex align-middle place-content-end gap-2">
                    <button class="readmore-btn text-gray-600">Read More</button>
                    <img src="../assets/program/read-more.png" width="25" alt />
                </p>
            </div>
        </section>

        <section
            class="xl:flex lg:gap-12 max-w-7xl mx-auto p-4 text-center lg:flex-row flex flex-col-reverse xl:text-left">
            <div class="lg:w-1/2">
                <h1 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4">
                    Program Highlights
                </h1>
                <div>
                    <ul class="divide-y text-left">
                        <li class="flex gap-4 p-2">
                            <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                            <p>
                                Strong foundation in management with courses in organizational behavior, accounting, and
                                business statistics
                            </p>
                        </li>

                        <li class="flex gap-4 p-2">
                            <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                            <p>
                                Specialized HR training in talent acquisition, labor laws, and performance management.
                            </p>
                        </li>

                        <li class="flex gap-4 p-2">
                            <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                            <p>
                                Global and strategic HR insights through courses in international business and HR
                                leadership.
                            </p>
                        </li>

                        <li class="flex gap-4 p-2">
                            <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                            <p>
                                Emphasizes data-driven decision-making with HR analytics and business analytics.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="border-r m-4 hidden lg:block"></div>
            <div class="lg:w-1/2">
                <h2 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4">
                    Eligibility
                </h2>
                <ul class="divide-y text-left">
                    <li class="flex gap-4 p-2">
                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                        <p>
                            Pass in an Undergraduate (Bachelor) Program of a minimum duration of three (3) years in any
                            stream from a UGC Recognized University, with a minimum aggregate of 50% or an equivalent
                            letter/numerical grade. A relaxation of 5% shall be given to SC/ST candidates.
                            <br /><br />
                            Candidates who are in the final semester of the Bachelors Program are also eligible to apply
                            for the Online MBA in Human Resource Management Program.
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex">
            <div class="px-4 lg:py-4 py-1 gap-6 justify-between">
                <h2 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4">
                    Career Opportunities
                </h2>

                <div class="text-left text-justify p-4">
                    <p> Offers dynamic career prospects across industries, enabling professionals to drive
                        organizational growth and workforce management.
                    </p>
                </div>

                <div class="px-4 lg:py-8 py-1 gap-6 flex flex-col lg:flex-row mx-0 justify-center">
                    <div class="flex lg:w-[50%] w-full">
                        <ul class="text-left">
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>HR Manager</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Talent Acquisition Specialist</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Organizational Development Consultant</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Compensation and Benefits Manager</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>HR Analytics Manager</p>
                            </li>
                        </ul>
                    </div>

                    <div class="flex lg:w-[80%] w-full">
                        <ul class="text-left">
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Employee Relations Manager</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Training and Development Manager</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p> HR Business Partner</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Labor Relations Specialist</p>
                            </li>
                            <li class="flex gap-4 p-2">
                                <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                <p>Diversity and Inclusion Manager</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="p-4 lg:py-8 py-2 max-w-7xl mx-auto">
            <h2 class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold text-center lg:p-4 p-0">
                Course Matrix
            </h2>
            <div class="lg:flex lg:gap-4">
                <div>
                    <div data-accordion="collapse" class>
                        <h2 id="accordion-collapse-heading-7" class="pt-4 moreBox1">
                            <button
                                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                                id="b6" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                aria-controls="accordion-collapse-body-7">
                                <span class="text-black">Semester 1 (20 Credits)</span>
                                <img src="../assets/about/read-more.png" width="20" alt />
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-7" class="hidden"
                            aria-labelledby="accordion-collapse-heading-7">
                            <div
                                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white">
                                <ul class="divide-y">
                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Management and Organizational Behavior
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Accounting for Managers
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Marketing Management
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Business Economics
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Business Communication
                                        </p>
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <h2 id="accordion-collapse-heading-8" class="pt-4 moreBox1">
                            <button
                                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                                id="b7" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false"
                                aria-controls="accordion-collapse-body-8">
                                <span class="text-black">Semester 2 (24 Credits)</span>
                                <img src="../assets/about/read-more.png" width="20" alt />
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-8" class="hidden"
                            aria-labelledby="accordion-collapse-heading-8">
                            <div
                                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white">
                                <ul class="divide-y">
                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Business Environment and Strategy
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Human Resource Management
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Corporate Finance
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Business Statistics for Decision Making
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Compensation Management and Employee Engagement

                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Consumer Behavior and Brand Management
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div data-accordion="collapse">
                        <h2 id="accordion-collapse-heading-9" class="pt-4 moreBox1">
                            <button
                                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                                id="b8" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false"
                                aria-controls="accordion-collapse-body-9">
                                <span class="text-black">Semester 3 (24 Credits)</span>
                                <img src="../assets/about/read-more.png" width="20" alt />
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-9" class="hidden"
                            aria-labelledby="accordion-collapse-heading-9">
                            <div
                                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white">
                                <ul class="divide-y">
                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Operations Management
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Talent Acquisition and Management
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Organizational Development and Change Management
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Performance Metrics and Analytics
                                        </p>
                                    </li>

                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>CFE
                                        </p>
                                    </li>
                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>CFE
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <h2 id="accordion-collapse-heading-10" class="pt-4 moreBox1 lg:block hidden">
                            <button
                                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                                id="b9" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false"
                                aria-controls="accordion-collapse-body-10">
                                <span class="text-black">Semester 4 (12 Credits)</span>
                                <img src="../assets/about/read-more.png" width="20" alt />
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-10" class="hidden"
                            aria-labelledby="accordion-collapse-heading-10">
                            <div
                                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white">
                                <ul class="divide-y">
                                    <li class="flex gap-4 p-2 items-center">
                                        <img src="../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
                                        <p>Research Based Learning</p>
                                    </li>


                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="flex justify-center mt-4">
                    <div class="flex align-middle place-content-end gap-2 lg:hidden" id="loadMore1">
                        <button class="readmore-btn text-gray-600">
                            <a href="#">View More</a>
                        </button>
                        <img src="../assets/program/read-more.png" width="25" alt />
                    </div>
                </div>
            </div>
        </section>
        <section class="max-w-7xl mx-auto lg:py-6 py-2">
            <h1 class="text-3xl text-gray-700 text-center font-bold p-2 lg:text-3xl text-[20px] sm:text-[20px]">
                Fee Structure
            </h1>
            <div class="mx-auto bg-white rounded-lg shadow-lg text-center p-2 lg:w-full w-[90%] sm:w-[90%]">
                <div class="m-4">
                    <!-- Tab content -->
                    <div class="tabcontent flex flex-col lg:flex-row">
                        <div class="w-full">
                            <h1
                                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]">
                                Domestic fee structure
                            </h1>
                            <table class="w-full border-4 border-white">
                                <tr class="bg-[#94C34D] text-white h-16">
                                    <th class="border-4 border-white">Yearly Fee Plan</th>
                                    <th class="border-4 border-white">Total Year Fee Plan</th>
                                </tr>
                                <tr class="bg-[#BDE6F7] h-10 text-center">
                                    <td class="border-4 border-white">₹ 25,000</td>
                                    <td class="border-4 border-white">₹ 50,000</td>
                                </tr>
                            </table>
                        </div>
                        <div class="w-full">
                            <h1
                                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]">
                                International fee structure
                            </h1>
                            <table class="w-full border-4 border-white">
                                <tr class="bg-[#94C34D] text-white h-16">
                                    <!-- <th class="border-4 border-white">Semester x 6</th> -->
                                    <th class="border-4 border-white">Yearly Fee Plan</th>
                                    <th class="border-4 border-white">Total Year Fee Plan</th>
                                </tr>
                                <tr class="bg-[#BDE6F7] h-10 text-center">
                                    <td class="border-4 border-white">$ 400</td>
                                    <td class="border-4 border-white">$ 800</td>
                                    <!-- <td class="border-4 border-white">$ 2,400</td> -->
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="is_widget">
            <div class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
                <div class="flex items-center justify-center min-h-screen">
                    <div class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4">
                        <div class="npf_wgts" data-height="344px" :data-w="this.widget_id2"></div>
                        <button @click="is_widget = false"
                            class="absolute top-0 right-1 bg-white rounded-full p-1 z-50">
                            <svg class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path fill-rule="evenodd"
                                    d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>

                        <div class>
                            <div class="flex items-center justify-center">
                                <div class="relative bg-white rounded-lg shadow-sm">
                                    <h4 class="font-bold text-lg px-2 py-2">
                                        Download Brochure
                                        <br />
                                    </h4>
                                    <div>
                                        <form class="space-y-3 p-4 font-normal" @submit.prevent="brochureSubmit">
                                            <input type="text" placeholder="Enter Name *" id="FirstName"
                                                class="p-1 rounded-sm w-full border-gray-300"
                                                v-model="formInfo.firstName" />
                                            <input type="email" placeholder="Enter Email Address *" id="EmailAddress"
                                                class="p-1 rounded-sm w-full border-gray-300"
                                                v-model="formInfo.email" />

                                            <input type="tel" placeholder="Enter Mobile Number *" id="phone"
                                                maxlength="10"
                                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                                class="p-1 rounded-sm w-full border-gray-300" required />

                                            <select name="mx_Program" id value="mx_Program"
                                                aria-placeholder="Select Program"
                                                class="p-1 rounded-sm w-full border-gray-300 text-base hidden" required>
                                                <option value="BCA">MBA</option>
                                            </select>
                                            <select name="mx_Elective" id="mx_Elective"
                                                aria-placeholder="Select Elective"
                                                class="p-1 rounded-sm w-full border-gray-300 text-base hidden">
                                                <option value="none">Select Specialization *</option>
                                                <option value=" Computer Science and IT">
                                                    General Management
                                                </option>
                                            </select>

                                            <button type="submit"
                                                class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center">
                                                Download Brochure &#10148;
                                            </button>

                                            <!-- <button
                            type="submit"
                            class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
                          >
                            Download Brochure &#10148;
                          </button>-->
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <faq-view />
        <footer-view />
    </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import FaqView from "../components/FaqView.vue";
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
import StickyView from "../components/StickyView.vue";
// import DownpopView from "@/components/DownpopView.vue";
import { useHead } from "@vueuse/head";
export default {
    components: { FaqView, StickyView, FooterView, NavigationView },
    data() {
        return {
            is_widget: false,
            showMenu: false,
            formInfo: {
                firstName: "",
                email: "",
                phone: "",
            },
        };
    },
    // created(){
    //   setTimeout(()=>{
    //     this.is_widget = true
    //   },2000)
    // },

    mounted() {
        $(".readmore-btn").on("click", function (e) {
            var target = $(e.target).parents(".readmore-section");
            var paragraph = target.find(".paragraph");

            if (paragraph.hasClass("hidden")) {
                paragraph.removeClass("hidden");
                $(this).text("Read Less");
            } else {
                paragraph.addClass("hidden");
                $(this).text("Read More");
            }
        });

        $(document).ready(function () {
            $(".moreBox1").slice(0, 3).show();
            if ($(".accordion-item:hidden").length != 0) {
                $("#loadMore1").show();
            }
            $("#loadMore1").on("click", function (e) {
                e.preventDefault();
                $(".moreBox1:hidden").slice(0, 4).slideDown();
                if ($(".moreBox1:hidden").length == 0) {
                    $("#loadMore1").fadeOut("slow");
                }
            });
        });
    },

    methods: {
        brochureSubmit(el) {
            console.log("formInput:::", el);
            let dataJson = [
                {
                    Attribute: "FirstName",
                    Value: el.target.elements.FirstName.value,
                },
                {
                    Attribute: "EmailAddress",
                    Value: el.target.elements.EmailAddress.value,
                },
                {
                    Attribute: "Phone",
                    Value: el.target.elements.phone.value,
                },
                {
                    Attribute: "mx_Program",
                    Value: "MBA",
                },

                {
                    Attribute: "mx_Elective",
                    Value: "MBA General Management",
                },
            ];
            console.log(dataJson);
            axios
                .post(
                    "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
                    dataJson,
                    { cross: true }
                )
                .then(async () => {
                    await fetch(
                        `https://api.onlineyenepoya.com/media/MBA_General_Management_30081.pdf`
                    )
                        .then((response) => response.blob())
                        .then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const a = document.createElement("a");
                            a.href = url;
                            a.download = `Yenepoya_MBA-General-Management.pdf`;
                            a.click();
                        });
                    setTimeout(() => {
                        window.location.href = "/brochure/MBA-thankyou";
                    }, 2000);
                })
                .catch((error) => {
                    // console.log(error.response.data.ExceptionMessage)
                    console.log(error);
                });
        },
    },

    setup() {
        useHead({
            // Can be static or computed
            title: "Online MBA in General Management | Yenepoya Online",
            meta: [
                {
                    name: `description`,
                    content:
                        "Experience growth and success in HR Domain with an Online MBA in Human Resource Management from Yenepoya Online. Start your journey to success",
                },
            ],

            link: [
                {
                    rel: "canonical",
                    href: "https://onlineyenepoya.com/programs/online-MBA-general-management",
                },
            ],
        });
    },
};
</script>

<style></style>