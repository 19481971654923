<template>
  <div>
    <section class="lg:block hidden">
      <div class="sidebar-call-holder"> <a href="tel:+919036009258"><span class="custom-tooltip">+919036009258</span><img alt="call" src="../assets/icos/sticky_call_icon.webp" width="50" height="50"> </a> <a href="https://api.whatsapp.com/send/?phone=919036009258&amp;text&amp;app_absent=0" target="_blank"><img alt="whatsapp" src="../assets/icos/WA-Icon.png" width="50" height="50"> </a> 
</div>
    </section>

    

 
  </div>
</template>

<script>
export default {
  
};
</script>

<style>

.sidebar-call-holder {
      position: fixed;
      top: 75%;
      right: 0;
      transform: translateY(-50%);
      z-index: 99;
      width: 75px;
  }

  .sidebar-call-holder1 {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 99;
    width: 75px;
}
  
  .sidebar-call-holder a {
      margin-bottom: 10px;
      margin-left: 0;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      position: relative;
  }

  .sidebar-call-holder1 a {
    margin-bottom: 10px;
    margin-right: 0;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
}
  
  span.custom-tooltip {
      padding: 8px 15px;
      white-space: nowrap;
      background: #e4e7ed;
      color: #7D7D7D;
      box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
      border-radius: 10px;
      position: absolute;
      z-index: -1;
      left: -185px;
      top: 15px;
      display: flex;
      align-items: center;
      transform: translateX(162px);
      opacity: 0;
      transition: all .3s ease;
  }
  .sidebar-call-holder a:hover .custom-tooltip{
      transform: translateX(0px);
      opacity: 1;
      transition: all .3s ease;
  }

  .sidebar-call-holder1 a:hover .custom-tooltip{
    transform: translateX(0px);
    opacity: 1;
    transition: all .3s ease;
}
  .custom-tooltip:before {
      content: "";
      position: absolute;
      right: -10px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      z-index: 9;
      width: 10px;
      border-left: 10px solid #e4e7ed;
      top: 11px;
  }
  .custom-tooltip .fa{margin-right: 5px; font-size: 20px;}
  a.fixed-call-btn {
      display: none;
      position: fixed;
      right: 5px;
      z-index: 99;
      bottom: 0;
      
  }



</style>
