<template>
  <div id="rank">
    <section class="bg-[#EEF6F9] p-4 lg:block hidden">
      <div class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg text-center">
        <h2
          class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-4"
        >
          Rankings & Recognition
        </h2>
        <div class="relative w-[90%] mx-auto py-4">
          <div class="flex justify-between justify-center">
            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end relative bg-[#94C34D] rounded-lg w-60 h-24 md:h-32 lg:h-28 -mr-8"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-9 left-3">
                      <img
                        src="../assets/ranking/Nirf.webp"
                        class="w-[80%]"
                        alt
                      />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    Ranked 95th in NIRF 2023 Rankings
                  </p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end relative bg-[#94C34D] rounded-lg w-60 h-24 md:h-32 lg:h-28 -mr-8"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-8 left-0.5">
                      <img src="../assets/ranking/ugc.webp" alt />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    Entitled by University Grants Commission
                  </p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end mx-auto relative bg-[#94C34D] rounded-lg w-60 h-24 md:h-32 lg:h-28"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-8 left-0.5">
                      <img src="../assets/ranking/naac_icon.webp" alt />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    National Assessment and Accreditation Council has awarded A+
                    Grade
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#EEF6F9] p-4 lg:hidden">
      <div class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg text-center">
        <h2
          class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-4"
        >
          Rankings & Recognition
        </h2>
        <div class="carousel relative w-[90%] mx-auto py-4">
          <div class="next-rank absolute top-[40%] -right-4 z-10">
            <img src="../assets/right-arrow.png" alt />
          </div>
          <div class="prev-rank absolute top-[40%] -left-4 z-[100]">
            <img src="../assets/left-arrow.png" alt />
          </div>
          <div class="slick-rank">
            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end relative bg-[#94C34D] rounded-lg w-52 sm:w-64 lg:w-60 h-24 md:h-32 lg:h-28 -mr-8"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-9 left-3">
                      <img
                        src="../assets/ranking/Nirf.webp"
                        class="w-[80%]"
                        alt
                      />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    Ranked 95th in NIRF 2023 Rankings
                  </p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end relative bg-[#94C34D] rounded-lg w-52 sm:w-64 lg:w-60 h-24 md:h-32 lg:h-28 -mr-8"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-8 left-0.5 px-2 pt-8">
                      <img src="../assets/ranking/ugc.webp" alt />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    Entitled by University Grants Commission
                  </p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="flex justify-center">
                <div
                  class="flex justify-end relative bg-[#94C34D] rounded-lg w-52 sm:w-64 lg:w-60 h-24 md:h-32 lg:h-28 -mr-8"
                >
                  <div class="absolute -left-8 md:-left-11 lg:-left-9">
                    <img
                      src="../assets/ranking/Eligibility.png"
                      class="drop-shadow-xl h-24 md:h-32 lg:h-28"
                      alt
                    />
                    <div class="absolute bottom-8 left-0.5">
                      <img src="../assets/ranking/naac_icon.webp" alt />
                    </div>
                  </div>
                  <p class="w-40 md:w-44 my-auto text-white text-sm text-left">
                    National Assessment and Accreditation Council has awarded A+
                    Grade
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  mounted() {
    $(".slick-rank").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-rank"),
      nextArrow: $(".next-rank"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
</script>

<style></style>
